import { render, staticRenderFns } from "./ItemPlannerItem.vue?vue&type=template&id=695b8d3b&scoped=true&"
import script from "./ItemPlannerItem.vue?vue&type=script&lang=js&"
export * from "./ItemPlannerItem.vue?vue&type=script&lang=js&"
import style0 from "./ItemPlannerItem.vue?vue&type=style&index=0&id=695b8d3b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "695b8d3b",
  null
  
)


    import installComponents from "!D:/IIS/rentmagic.website.vue.vlaams-brabant/prod/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BCol, BRow} from 'bootstrap-vue'
    installComponents(component, {BButton, BCol, BRow})
    

export default component.exports