<template>
  <div class="page-container">
    <b-container v-if="loaded">
      <Breadcrumbs
        :bread-crumbs="breadCrumbs"
        :currentpage="item.Description"
      />

      <b-row>
        <b-col cols="12">
          <h1>{{ item.Description }}</h1>
          <div v-if="paAttestRequired || riscoAnalyseRequired">
            <b-alert v-if="paAttestRequired" show variant="warning"
              ><font-awesome-icon icon="exclamation-circle" /> Voor dit product
              is een PA-attest vereist.
            </b-alert>
            <b-alert v-if="riscoAnalyseRequired" show variant="warning"
              ><font-awesome-icon icon="exclamation-circle" /> Voor dit product
              is een risicoanalyse vereist.
            </b-alert>
          </div>
          <b-alert v-if="item.CUST_AttentionFrontendEnd" show variant="warning"
            ><font-awesome-icon icon="exclamation-circle" />
            <span v-html="item.CUST_AttentionFrontendEnd" />
          </b-alert>
        </b-col>
        <b-col class="mb-3" sm="12" md="12" lg="12" xl="8">
          <item-composition-content
            v-if="
              items.length > 0 &&
                (item.Type == 'Composition' ||
                  item.Type == 'Package' ||
                  item.Type == undefined)
            "
            :items="items"
            :item="item"
            :item-type="item.Type"
          ></item-composition-content>
          <SingleItemContent
            v-if="item.Type == 'Item'"
            :item="item"
          ></SingleItemContent>
        </b-col>
        <b-col sm="12" md="12" lg="12" xl="4">
          <ItemPlanner :item="item" />
        </b-col>
      </b-row>
      <b-row v-if="suggestedItems.length > 0" class="pb-5">
        <b-col cols="12" class="pt-3 pb-0 mb-0">
          <h3 class="mb-0">De meeste mensen bestelden ook:</h3>
        </b-col>
        <b-col
          v-for="suggestedItem in suggestedItems"
          :key="suggestedItem.ID"
          class="mt-0 pt-0"
          xs="12"
          sm="6"
          lg="4"
          xl="3"
          ><SuggestedItem :item="suggestedItem" :suggested-item="true"
        /></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { getItem, getSuggestedItems } from '@/services/ItemService'
import ItemPlanner from './ItemPlanner'
import ItemCompositionContent from './ItemCompositionContent'
import Breadcrumbs from '@/components/header/BreadCrumbs'
import SingleItemContent from './SingleItemContent'
import SuggestedItem from '@/components/items/Item'
import { generateBreadCrumbs } from '@/services/Breadcrumbs'
import GetItemSEODataBySlug from '@/services/item/GetItemSEODataBySlug'

export default {
  name: 'Item',
  components: {
    ItemPlanner,
    Breadcrumbs,
    SingleItemContent,
    'item-composition-content': ItemCompositionContent,
    SuggestedItem
  },
  data() {
    return {
      suggestedItems: [],
      breadCrumbs: [],
      item: {},
      items: [],
      metaTitle: '',
      metaDescription: '',
      loaded: false
    }
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      titleTemplate: '%s - Vlaams-Brabant Uitleendienst',
      meta: [
        {
          name: 'description',
          content: `${this.stripHtml(this.metaDescription)}`,
          vmid: 'og:meta-description'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `${this.generateCanonical() ?? ''}`
        }
      ]
    }
  },
  computed: {
    itemID: function() {
      return this.$route.params.itemID
    },
    paAttestRequired: function() {
      if (this.item.RequiredTestimonies) {
        return (
          this.item.RequiredTestimonies.filter(
            testimony => testimony.Description === 'PA Attest'
          ).length > 0
        )
      }
      return false
    },
    riscoAnalyseRequired: function() {
      if (this.item.RequiredTestimonies) {
        return (
          this.item.RequiredTestimonies.filter(
            testimony => testimony.Description === 'Risico analyse'
          ).length > 0
        )
      } else {
        return false
      }
    },
    subCategorySlugFromItem: function() {
      return this.getLastWord(this.item?.CategorySlugs)
    }
  },
  watch: {
    item: function() {
      this.items = this.item.Items ? this.item.Items : []
    }
  },
  async beforeMount() {
    if (this.$route.params.itemID != null) {
      let categoryDescription = this.$route.params.itemID
      if (categoryDescription.includes('-')) {
        categoryDescription = categoryDescription.replace('-', ' ')
      }
      // set first letter to uppercase
      categoryDescription =
        categoryDescription.charAt(0).toUpperCase() +
        categoryDescription.slice(1)

      this.metaDescription = categoryDescription
    }
    const itemSEOData = await GetItemSEODataBySlug({
      slug: this.$route.params.itemID
    })

    this.metaTitle = itemSEOData[0]?.Description ?? ''
    this.metaDescription = itemSEOData[0]?.MediumDescription ?? ''
    this.loaded = true
  },
  async created() {
    this.setTitle()
    this.item = await getItem({ itemID: this.$route.params.itemID, slug: true })
    this.metaTitle = this.item.Description
    this.metaDescription =
      this.item.MediumDescription ??
      this.item.LongDescription ??
      this.item.Description

    if (this.item.ID == null) {
      this.$router.push({ name: '404' })
    }

    if (this.item.Type !== 'Package') {
      this.breadCrumbs = await generateBreadCrumbs({
        categoryID:
          this.$route.params.subcategoryID || this.subCategorySlugFromItem,
        item: true
      })
    } else {
      this.breadCrumbs = [
        { path: '/index', title: 'Uitleendienst' },
        { path: '/pakketten', title: 'Pakketten' }
      ]
    }
    this.suggestedItems = await getSuggestedItems({ itemID: this.item.ID })
  },
  methods: {
    setTitle() {
      let title = this.$route.params.itemID
      // capitalize first letter of title
      title = title.charAt(0).toUpperCase() + title.slice(1)
      //strip -
      title = title.replace(/-/g, ' ')

      this.metaTitle = `${title} - Vlaams-Brabant Uitleendienst`
    },
    generateCanonical() {
      return `${window.location.origin}/catalogus/artikel/${this.$route.params.itemID}`
    },
    stripHtml: function(html) {
      var tmp = document.createElement('DIV')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    },
    getLastWord: function(string) {
      if (!string) return ''
      return string
        .replace(/,/g, ' ')
        .replace(/  +/g, ' ')
        .trim()
        .split(' ')
        .splice(-1)
    }
  }
}
</script>
<style scoped lang="scss">
h1 {
  font-size: 45px;
  font-family: 'DecimaPro-Bold' !important;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: 'DecimaPro';
}

h3 {
  font-size: 35px;
}

.item-information {
  background-color: white;
  border-radius: 5px;
  padding: 30px;
}

ul li {
  margin-bottom: 5px;
}

.vhd-input {
  display: block;
  width: 100%;
  height: add(1.5em, add(0.75rem, 2px, false));
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
</style>
