<template>
  <div class="item-planner-single pt-2">
    <p v-if="itemType == 'Package'" class="product mb-0">
      {{ item.Description }}
      <span>
        -
        {{ itemAmount }}
        {{ itemAmount > 1 ? "stuks" : "stuk" }}</span
      >
    </p>

    <h5 v-if="warehouseItemTitleInfo">{{ warehouseItemTitleInfo }}</h5>
    <h5 v-if="warehouseItemTitleError" class="text-danger">
      {{ warehouseItemTitleError }}
    </h5>
    <p v-if="warehouseItemInfo" v-html="warehouseItemInfo" />

    <div
      v-if="
        warehouse.HasStock &&
        startDateAvailableTimePeriods &&
        endDateAvailableTimePeriods &&
        warehouse.MaxCustomerStock &&
        (dateEditable || currentWarehouse == warehouse.WarehouseID)
      "
    >
      <b-row>
        <b-col
          v-if="itemType != 'Package'"
          xs="12"
          sm="6"
          class="amount pt-1 pb-4 pb-sm-2 text-center text-sm-left"
        >
          <b-button
            :disabled="addToCartLoading"
            @click="
              changeAmount({
                amount: amount - 1,
              })
            "
            >-</b-button
          >

          <span class="pl-2 pr-2">{{ amount }}</span>

          <b-button
            :disabled="addToCartLoading"
            @click="
              changeAmount({
                amount: amount + 1,
                warehouse: warehouse,
              })
            "
            >+</b-button
          >
        </b-col>
        <b-col xs="12" sm="6">
          <b-button
            v-if="
              warehouse.MaxCustomerStock &&
              itemType != 'Package' &&
              amount > 0 &&
              (dateEditable || currentWarehouse == warehouse.WarehouseID)
            "
            href="#"
            variant="primary"
            block
            :disabled="addToCartLoading || updatingItem || invalidDate"
            @click="addToCart(warehouse.WarehouseID)"
          >
            <span v-if="!addToCartLoading">In Winkelmandje</span>
            <font-awesome-icon
              v-if="addToCartLoading"
              class="fa-spin"
              far
              icon="spinner"
            />
          </b-button>
        </b-col>
      </b-row>
      <h4
        v-if="
          amount > 0 &&
          itemType != 'Package' &&
          warehouse.MaxCustomerStock &&
          amount > 0 &&
          (dateEditable || currentWarehouse == warehouse.WarehouseID)
        "
        class="mt-3"
      >
        Prijs:
        {{
          (Math.round((warehouse.Price * amount + Number.EPSILON) * 100) / 100)
            .toFixed(2)
            .replace(".", ",")
        }}
        euro
      </h4>
      <p v-if="itemType !== 'Package'" class="price-comment">
        Getoonde prijzen zijn excl. administratiekost 10 euro
      </p>
      <alternative-date-suggestion
        v-if="startDate && endDate && tabActive && itemType != 'Package'"
        :price-difference="warehouse.PriceDifference"
        :previous-return-day="warehouse.PreviousReturnDay"
        :start-date="startDate"
        :end-date="endDate"
        :warehouse="selectedWarehouse"
        :current-price="warehouse.Price"
      />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import { addToCart, getCart } from "@/services/CartService";
import ItemPlannerItemAlternativeDateSuggestion from "./ItemPlannerItemAlternativeDateSuggestion";

export default {
  components: {
    "alternative-date-suggestion": ItemPlannerItemAlternativeDateSuggestion,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: function () {
        return null;
      },
    },
    startDate: {
      type: String,
      required: true,
      default: function () {
        return null;
      },
    },
    endDate: {
      type: String,
      required: true,
      default: function () {
        return null;
      },
    },
    dateEditable: {
      type: Boolean,
      required: true,
      default: function () {
        return null;
      },
    },
    currentWarehouse: {
      type: String,
      required: true,
      default: function () {
        return "";
      },
    },
    selectedWarehouse: {
      type: String,
      required: true,
      default: function () {
        return "";
      },
    },
    updatingItem: {
      type: Boolean,
      required: true,
      default: function () {
        return "";
      },
    },
    itemType: {
      type: String,
      required: true,
      default: function () {
        return null;
      },
    },
    amountOfItems: {
      type: Number,
      required: false,
      default: function () {
        return null;
      },
    },
    invalidDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    startDateAvailableTimePeriods: {
      type: String,
      required: false,
      default: null,
    },
    endDateAvailableTimePeriods: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      addToCartLoading: false,
      alternativePrice: 0,
      amount: 1,
      itemAmount: 1,
    };
  },
  computed: {
    tabActive() {
      return this.currentWarehouse === this.selectedWarehouse;
    },
    warehouseItemTitleError: function () {
      if (this.updatingItem) return "";

      if (
        !this.dateEditable &&
        this.currentWarehouse != this.warehouse.WarehouseID
      )
        return `Je kan dit artikel niet aanvragen in deze afhaallocatie omdat je al artikelen in je winkelmandje hebt van een andere afhaallocatie.`;

      if (
        this.itemType === "Package" &&
        this.amountOfItems > this.warehouse.MaxCustomerStock
      )
        return "Je hebt het maximaal toegestane aantal van dit artikel bereikt";

      if (
        this.startDateAvailableTimePeriods === "Closed" &&
        this.endDateAvailableTimePeriods === "Closed"
      )
        return "De geselecteerde uitleenbalie is gesloten op de gekozen afhaaldatum en terugbrengdatum";
      if (
        this.startDateAvailableTimePeriods === "Full" &&
        this.endDateAvailableTimePeriods === "Full"
      )
        return "De geselecteerde uitleenbalie is volgeboekt op beide de gekozen afhaaldatum en terugbrengdatum";
      if (
        this.startDateAvailableTimePeriods === "Closed" &&
        this.startDateAvailableTimePeriods === "Full"
      )
        return "De geselecteerde uitleenbalie is gesloten op de gekozen afhaaldatum en volgeboekt op de gekozen terubrengdatum";
      if (
        this.startDateAvailableTimePeriods === "Full" &&
        this.endDateAvailableTimePeriods === "Closed"
      )
        return "De geselecteerde uitleenbalie is volgeboekt op de gekozen afhaaldatum en gesloten op de gekozen terubrengdatum";
      if (this.startDateAvailableTimePeriods == "Closed")
        return "De geselecteerde uitleenbalie is gesloten op de gekozen afhaaldatum";
      if (this.startDateAvailableTimePeriods == "Full")
        return "De geslecteerde uitleenbalie is volgeboekt op de gekozen afhaaldatum";
      if (this.endDateAvailableTimePeriods === "Closed")
        return "De geselecteerde uitleenbalie is gesloten op de gekozen terugbrengdatum";
      if (this.endDateAvailableTimePeriods === "Full")
        return "De geselecteerde uitleenbalie is volgeboekt op de gekozen terugbrengdatum";

      if (
        (!this.warehouse.HasStock || this.warehouse.MaxCustomerStock < 1) &&
        (this.dateEditable ||
          this.currentWarehouse == this.warehouse.WarehouseID)
      ) {
        if (this.warehouse.HasStock && this.warehouse.MaxCustomerStock < 1)
          return "Je hebt het maximaal toegestane aantal van dit artikel bereikt";

        if (!this.warehouse.HasStock)
          return `Niet meer beschikbaar in ${this.warehouse.WarehouseID}`;
      }

      return null;
    },
    warehouseItemTitleInfo: function () {
      if (
        this.warehouse.MaxCustomerStock &&
        this.startDateAvailableTimePeriods &&
        this.endDateAvailableTimePeriods &&
        this.warehouse.HasStock &&
        (this.dateEditable ||
          this.currentWarehouse == this.warehouse.WarehouseID)
      )
        return `Beschikbaar in ${this.warehouse.WarehouseID}`;
      return null;
    },
    warehouseItemInfo: function () {
      if (this.warehouse.NextStartDate)
        return `De eerstvolgende beschikbare afhaaldatum is ${dayjs(
          this.warehouse.NextStartDate
        ).format("DD/MM/YYYY")}`;

      if (this.warehouse.PreviousEndDate)
        return `De volgende eindatum is nog wel beschikbaar ${dayjs(
          this.warehouse.PreviousEndDate
        ).format("DD/MM/YYYY")}`;

      if (this.warehouse.NextEndDate)
        return `De eerstvolgende beschikbare terugbrengdatum is ${dayjs(
          this.warehouse.NextEndDate
        ).format("DD/MM/YYYY")}`;

      if (
        this.warehouse.MaxCustomerStock &&
        this.warehouse.HasStock &&
        this.itemType != "Package" &&
        this.startDateAvailableTimePeriods &&
        this.endDateAvailableTimePeriods &&
        (this.dateEditable ||
          this.currentWarehouse == this.warehouse.WarehouseID)
      ) {
        if (this.warehouse.Stock < this.warehouse.MaxCustomerStock) {
          return `Maximaal ${Math.round(
            this.warehouse.Stock
          )} stuks tegelijk beschikbaar.`;
        } else {
          return `Maximaal ${Math.round(
            this.warehouse.MaxCustomerStock
          )} stuks tegelijk beschikbaar.`;
        }
      }

      if (
        !this.dateEditable &&
        this.itemType != "Package" &&
        this.currentWarehouse != this.warehouse.WarehouseID
      )
        return `Wijzig je afhaallocatie in je <a href="/winkelmandje">winkelmandje</a>.`;
      return null;
    },
    warehouse: function () {
      if (this.item.Warehouses) {
        return this.item.Warehouses.filter((obj) => {
          return obj.WarehouseID === this.selectedWarehouse;
        })[0];
      } else {
        return {};
      }
    },
    getAlternativeReturnPrice: function () {
      if (dayjs(this.endDate).weekday(4)) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    warehouse: async function () {
      this.$emit("updatePrice", { warehouse: this.selectedWarehouse });
      if (
        this.warehouse.MaxCustomerStock < this.amount &&
        this.warehouse.MaxCustomerStock != null
      ) {
        //this.amount = this.warehouse.MaxCustomerStock
      }
      if (
        this.warehouse.HasStock &&
        this.warehouse.MaxCustomerStock > 0 &&
        this.item.Amount > 0
      ) {
        //this.amount = this.item.Amount
      }
      if (this.warehouse.MaxCustomerStock > 0) {
        this.amount = 1;
      } else {
        this.amount = 0;
      }

      this.item.Amount = this.amount;
      if (this.amountOfItems) {
        this.amount = this.amountOfItems;
        this.item.Amount = this.amountOfItems;
      }
      this.$store.commit("addItemStoreItem", {
        key: `${this.item.ID}-${this.selectedWarehouse}`,
        item: this.item,
      });
    },
    amount: function () {
      this.item.Amount = this.amount;
      if (this.amountOfItems) {
        this.amount = this.amountOfItems;
        this.item.Amount = this.amountOfItems;
      }
      this.$store.commit("addItemStoreItem", {
        key: `${this.item.ID}-${this.selectedWarehouse}`,
        item: this.item,
      });
    },
  },
  created() {
    if (
      this.warehouse.HasStock &&
      this.warehouse.MaxCustomerStock > 0 &&
      this.item.Amount > 0
    ) {
      this.amount = this.item.Amount;
    }

    // If we are a package set the amounts from the amounts described in the package item information
    if (this.amountOfItems) {
      this.amount = this.amountOfItems;
      this.item.Amount = this.amountOfItems;
    }

    this.$store.commit("addItemStoreItem", {
      key: `${this.item.ID}-${this.selectedWarehouse}`,
      item: this.item,
    });

    this.itemAmount = this.amount;
    this.$emit("updatePrice", { warehouse: this.selectedWarehouse });
  },
  methods: {
    changeAmount: function ({ amount, warehouse }) {
      if (amount > this.warehouse.MaxCustomerStock) return;
      if (amount > 0) {
        this.amount = amount;

        if (warehouse && warehouse.MaxCustomerStock) {
          this.amount = Math.min(amount, warehouse.MaxCustomerStock);
        }

        if (warehouse && warehouse.Stock) {
          this.amount = Math.min(amount, warehouse.Stock);
        }
      }

      this.$emit("updatePrice", { warehouse: this.selectedWarehouse });
      this.item.Amount = this.amount;

      this.$store.commit("addItemStoreItem", {
        key: `${this.item.ID}-${this.selectedWarehouse}`,
        item: this.item,
      });
    },
    addToCart: async function (warehouseID, amount) {
      this.addToCartLoading = true;
      let itemID = null;
      let compositionID = null;

      if (!amount) {
        amount = this.amount;
      }

      if (this.item.Type == "Composition") {
        compositionID = this.item.ID;
      } else {
        itemID = this.item.ID;
      }

      await addToCart({
        itemID,
        compositionID,
        dateTimeBusinessStart: this.startDate,
        dateTimeBusinessEnd: this.endDate,
        warehouseID: warehouseID,
        amount: amount,
      });

      this.$emit("updateItemData");
      if (this.itemType !== "Package") {
        this.amount = 1;
      }
      await getCart();

      this.addToCartLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.price-comment {
  font-size: 12px;
  font-family: "Roboto" !important;
  font-weight: 200;
}
.alternative-price {
  color: #015ea5;
  font-size: 14px;
  font-family: "Roboto";
}
.amount {
  .btn {
    border: 1px solid #efefef;
    background-color: white;
    color: black;
    height: 41px;
    width: 41px;
    line-height: 0.5px;
  }
}
h4,
label,
p,
.btn {
  font-family: "DecimaPro-Bold";
  text-decoration: none;
  span {
    font-family: "DecimaPro-Bold";
    text-decoration: none;
  }
}
.product {
  font-family: "DecimaPro-Bold";
  font-size: 18px;
}
</style>
