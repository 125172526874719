import { axiosCreate } from '@/services/Api'

export default async function({ startDate, endDate, warehouse }) {
  const result = await axiosCreate.get(`/CustClosedRentalDates`, {
    params: {
      startDate,
      endDate,
      warehouse
    }
  })

  return result.data
}
