<template>
  <div>
    <h4>Links</h4>
    <ul>
      <li v-for="file in attachments" :key="file.FileName">
        <a :href="`${appURL}${file.AttachmentUri}`"
          ><span v-if="file.ContentType == '.docx'" class="pr-2"
            ><font-awesome-icon icon="file-word"/></span
          ><span v-if="file.ContentType == '.pdf'" class="pr-2"
            ><font-awesome-icon icon="file-pdf"/></span
          >{{ file.FileName.split('.')[0] }}</a
        >
        <span v-if="file.FileLength" class="fileMeta"
          >&nbsp;({{ file.ContentType.replace('.', '').toUpperCase() }},
          <span v-if="file.NumberOfPages" class="fileMeta"
            >{{ file.NumberOfPages }}blz.,</span
          >
          {{ (file.FileLength / 1000).toFixed(2) + 'kb' }})</span
        >
        <span v-else class="fileMeta"
          >&nbsp;({{ file.ContentType.replace('.', '').toUpperCase() }})</span
        >
      </li>
      <li v-if="item.CUST_Instruction" class="pt-3 link">
        <a :href="item.CUST_Instruction" target="_blank"
          >{{ item.CUST_InstructionTitle }}
          <font-awesome-icon icon="external-link"
        /></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  computed: {
    appURL: function() {
      return process.env.VUE_APP_URL
    },
    attachments() {
      if (this.item.Type === 'Composition') {
        const attachments = []

        //loop through items in this.item.Items and add all ItemAttachments to attachments
        this.item.Items.forEach(item => {
          item.ItemAttachments.forEach(attachment => {
            attachments.push(attachment)
          })
        })
        return attachments
      }
      return this.item.ItemAttachments
    }
  }
}
</script>

<style></style>
