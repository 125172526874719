var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item-planner-single pt-2"},[(_vm.itemType == 'Package')?_c('p',{staticClass:"product mb-0"},[_vm._v(" "+_vm._s(_vm.item.Description)+" "),_c('span',[_vm._v(" - "+_vm._s(_vm.itemAmount)+" "+_vm._s(_vm.itemAmount > 1 ? "stuks" : "stuk"))])]):_vm._e(),(_vm.warehouseItemTitleInfo)?_c('h5',[_vm._v(_vm._s(_vm.warehouseItemTitleInfo))]):_vm._e(),(_vm.warehouseItemTitleError)?_c('h5',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.warehouseItemTitleError)+" ")]):_vm._e(),(_vm.warehouseItemInfo)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.warehouseItemInfo)}}):_vm._e(),(
      _vm.warehouse.HasStock &&
      _vm.startDateAvailableTimePeriods &&
      _vm.endDateAvailableTimePeriods &&
      _vm.warehouse.MaxCustomerStock &&
      (_vm.dateEditable || _vm.currentWarehouse == _vm.warehouse.WarehouseID)
    )?_c('div',[_c('b-row',[(_vm.itemType != 'Package')?_c('b-col',{staticClass:"amount pt-1 pb-4 pb-sm-2 text-center text-sm-left",attrs:{"xs":"12","sm":"6"}},[_c('b-button',{attrs:{"disabled":_vm.addToCartLoading},on:{"click":function($event){return _vm.changeAmount({
              amount: _vm.amount - 1,
            })}}},[_vm._v("-")]),_c('span',{staticClass:"pl-2 pr-2"},[_vm._v(_vm._s(_vm.amount))]),_c('b-button',{attrs:{"disabled":_vm.addToCartLoading},on:{"click":function($event){return _vm.changeAmount({
              amount: _vm.amount + 1,
              warehouse: _vm.warehouse,
            })}}},[_vm._v("+")])],1):_vm._e(),_c('b-col',{attrs:{"xs":"12","sm":"6"}},[(
            _vm.warehouse.MaxCustomerStock &&
            _vm.itemType != 'Package' &&
            _vm.amount > 0 &&
            (_vm.dateEditable || _vm.currentWarehouse == _vm.warehouse.WarehouseID)
          )?_c('b-button',{attrs:{"href":"#","variant":"primary","block":"","disabled":_vm.addToCartLoading || _vm.updatingItem || _vm.invalidDate},on:{"click":function($event){return _vm.addToCart(_vm.warehouse.WarehouseID)}}},[(!_vm.addToCartLoading)?_c('span',[_vm._v("In Winkelmandje")]):_vm._e(),(_vm.addToCartLoading)?_c('font-awesome-icon',{staticClass:"fa-spin",attrs:{"far":"","icon":"spinner"}}):_vm._e()],1):_vm._e()],1)],1),(
        _vm.amount > 0 &&
        _vm.itemType != 'Package' &&
        _vm.warehouse.MaxCustomerStock &&
        _vm.amount > 0 &&
        (_vm.dateEditable || _vm.currentWarehouse == _vm.warehouse.WarehouseID)
      )?_c('h4',{staticClass:"mt-3"},[_vm._v(" Prijs: "+_vm._s((Math.round((_vm.warehouse.Price * _vm.amount + Number.EPSILON) * 100) / 100) .toFixed(2) .replace(".", ","))+" euro ")]):_vm._e(),(_vm.itemType !== 'Package')?_c('p',{staticClass:"price-comment"},[_vm._v(" Getoonde prijzen zijn excl. administratiekost 10 euro ")]):_vm._e(),(_vm.startDate && _vm.endDate && _vm.tabActive && _vm.itemType != 'Package')?_c('alternative-date-suggestion',{attrs:{"price-difference":_vm.warehouse.PriceDifference,"previous-return-day":_vm.warehouse.PreviousReturnDay,"start-date":_vm.startDate,"end-date":_vm.endDate,"warehouse":_vm.selectedWarehouse,"current-price":_vm.warehouse.Price}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }