<template>
  <div id="app">
    <agile ref="main" class="main" :options="options" :as-nav-for="asNavFor1">
      <div
        v-for="(image, imageIndex) in images"
        :key="imageIndex"
        class="slide"
        :class="`slide--${imageIndex}`"
        @click="galleryIndex = imageIndex"
      >
        {{ imageIndex }}
        <img
          :alt="
            image.FileName.substr(0, image.FileName.lastIndexOf('.')) || input
          "
          :src="`${appURL}${image.ImageUrl}`"
        />
      </div>
      <template v-if="images.length > 1" slot="prevButton">
        <font-awesome-icon icon="chevron-left" />
      </template>
      <template v-if="images.length > 1" slot="nextButton">
        <font-awesome-icon icon="chevron-right" />
      </template>
    </agile>
    <gallery
      :id="randomString"
      :images="slides"
      :index="galleryIndex"
      :options="{ container: `#${randomString}` }"
      @close="galleryIndex = null"
    ></gallery>
  </div>
</template>
<script>
import { VueAgile } from 'vue-agile'
import Gallery from 'vue-gallery'
export default {
  components: {
    agile: VueAgile,
    gallery: Gallery
  },
  props: {
    images: {
      required: true,
      type: Array,
      default: function() {
        return [
          {
            ImageID: 'i00481',
            Ranking: 0,
            ContentType: null,
            FileName: 'default foto.png',
            ItemID: '011016',
            DateTimeModified: null,
            DateTimeCreated: '2020-05-12T13:09:27.66',
            UserCreated: '302ff530-0fda-462c-846d-6be5c456bbc9',
            UserModified: null,
            ImageUrl:
              '/Handlers/ImageHandler.ashx?TableName=Rental.Item&ItemID=011016&ImageID=i00481'
          },
          {
            ImageID: 'i00483',
            Ranking: 2,
            ContentType: null,
            FileName: 'ynwa.PNG',
            ItemID: '011016',
            DateTimeModified: null,
            DateTimeCreated: '2020-05-12T13:11:12.38',
            UserCreated: '302ff530-0fda-462c-846d-6be5c456bbc9',
            UserModified: null,
            ImageUrl:
              '/Handlers/ImageHandler.ashx?TableName=Rental.Item&ItemID=011016&ImageID=i00483'
          }
        ]
      }
    },
    sliderControls: {
      required: false,
      type: Boolean,
      default: true
    },
    itemSlug: {
      required: true,
      type: String,
      default: function() {
        return 'itemSlug'
      }
    }
  },
  data: function() {
    return {
      galleryIndex: null,
      asNavFor1: [],
      asNavFor2: [],
      options: {
        dots: false,
        fade: true,
        navButtons: false,
        responsive: [
          {
            breakpoint: 600
          },

          {
            breakpoint: 1000,
            settings: {
              navButtons: true
            }
          }
        ]
      },
      options2: {
        autoplay: false,
        centerMode: true,
        dots: false,
        navButtons: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2
            }
          },

          {
            breakpoint: 1000,
            settings: {
              navButtons: true
            }
          }
        ]
      }
    }
  },
  computed: {
    appURL: function() {
      return process.env.VUE_APP_URL
    },
    randomString: function() {
      const length = 6
      let result = ''
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      let charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    },
    slides: function() {
      return this.images.map(image => `${this.appURL}${image.ImageUrl}`)
    }
  },
  mounted() {
    this.asNavFor1.push(this.$refs.thumbnails)
    this.asNavFor2.push(this.$refs.main)
    if (this.slides.length < 2 || this.sliderControls === false) {
      this.options.responsive[1].settings.navButtons = false
      this.options2.responsive[1].settings.navButtons = false
    }
  }
}
</script>
<style lang="scss">
.main {
  margin-bottom: 30px;
}

.thumbnails {
  margin: 0 -5px;
  width: calc(100% + 10px);
}

.slide.slide--0.agile__slide.agile__slide--current.agile__slide--active {
  width: 100% !important;
}
.agile__slides {
  width: 100%;
}
.agile__nav-button {
  background: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 24px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.agile__nav-button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 25px;
}
.agile__nav-button--prev {
  left: -15px;
}
.agile__nav-button--next {
  right: -15px;
}
.agile__nav-button:hover {
  color: #888;
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: #888;
}

.slide {
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}
.slide--thumbniail {
  cursor: pointer;
  height: 100px;
  padding: 0 5px;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.slide--thumbniail:hover {
  opacity: 0.75;
}
.slide img {
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
  cursor: pointer;
}
</style>
