import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'

dayjs.extend(isoWeek)

/**
 * Adds week days to the dayjs date object skipping weekends
 * @param {dayjs} date dayjs datetime object
 * @param {Number} days Number of days
 * @returns {dayjs} dayjs Object with the added days
 */
export default function addWeekdays(date, days) {
  date = dayjs(date) // use a clone
  while (days > 0) {
    date = date.add(1, 'days')
    // decrease "days" only if it's a weekday.
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      days -= 1
    }
  }
  return date
}
